/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* .glowing-post {
    fill: white;
    filter: drop-shadow(0 0 20px rgba(66, 153, 225, 0.7)); /* Simplified to one shadow */
    /* animation: glow 2s infinite alternate;
  }
  
  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 10px rgba(66, 153, 225, 0.5));
    }
    100% {
      filter: drop-shadow(0 0 20px rgba(66, 153, 225, 0.7));
    /* }
  }
   */ 